import React from "react";
import { Background, BackgroundPanelContainer } from "./styles";
import Particles from "react-tsparticles"; 
import { loadFull } from "tsparticles"; 
import colors from "../../../config/colors";
import { isMobile } from "../../../common/utils";
import BackgroundImage  from "./bg.png";
function BackgroundPanel() {
  return <BackgroundPanelContainer src={BackgroundImage}>
    
  </BackgroundPanelContainer>;
}
export default BackgroundPanel;