import styled from "styled-components";
import { isMobile } from "../../../common/utils";
import colors from "../../../config/colors";
import { ButtonContainer } from "../../../common/Button/styles";
import { panelSpacing } from "../../../common/styles";

export const HomePanelContainer = styled.div`
    display:flex;
    align-items:flex-end;
    justify-content:space-between;
    height:calc(100vh - 100px);
    ${isMobile() && `
        flex-direction:column;
    ` }
`;

export const DescriptionPanel = styled.div`
    padding:100px;
    width:50%;
    box-sizing: border-box;
    display:flex;
    align-items:flex-end;
    justify-content:center;
    flex-direction:column;
    height:100%;
    ${isMobile() && `
        width:100%;
        padding:${panelSpacing}px;
    ` }
`;
export const Title = styled.div`
    font-size:48px;
    font-weight:bold;
    color:${colors.primary};
    width:100%;
    
`;
export const Description = styled.div`
    font-size:24px;
    margin:50px 0px;
    width:100%;
`;

export const ImagePanel = styled.div`
    background-color:${colors.primary}11;
    height:80%;
    width:40%;
    border-radius:300px 0px 0px 0px;
    display:flex;
    align-items:flex-end;
    justify-content:flex-end;
`;
export const Image = styled.img`
    width:98%;
`;
export const BigButton = styled(ButtonContainer)`
    background-color:${colors.primary};
    padding:${panelSpacing}px
    border-radius:10px;
    color:${colors.background};
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
`;