import styled from "styled-components";
import { borderRadius } from "../../../common/styles";
import { isMobile } from "../../../common/utils";
import colors from "../../../config/colors";

export const ServicesPanelContainer = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    padding:5%;
    width:100%;
    box-sizing: border-box;
    background-color:${colors.background};

    
`;


export const ServicesTitle = styled.div`
    font-size:48px;
    font-weight:bold;
    margin-bottom:10px;
    color:${colors.primary};
    text-align:center;
@media(max-width:991px) {
    margin-top:40px;
}
`;
export const ServicesSubText = styled.div`
    font-size:18px;
    margin-bottom:60px;
    text-align:center;
`;
export const ServiceCards = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    ${isMobile() && `
        flex-direction:column;
    `}
`;
export const ServiceCard = styled.div`
    max-width:30%;
    background-color:${colors.secondary}77;
    border-radius:${borderRadius}px;
    padding:40px;
    box-sizing:border-box;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    margin-bottom:20px;
    ${isMobile() && `
        max-width:95%;
    `}
`;
export const ServiceImage = styled.svg`
    height:200px;
    width:200px;
    margin-bottom:20px;
`;
export const ServiceCardTitle = styled.div`
    font-size:24px;
    font-weight:bold;
    color:${colors.primary};
    margin-bottom:10px;
`;
export const ServiceCardText = styled.div`
    font-size:18px;
    text-align:center;
`;