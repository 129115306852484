import React from "react";
import BackgroundPanel from "./BackgroundPanel";
import Footer from "./Footer";
import Header from "./Header";
import HomePanel from "./HomePanel";
import ServicesPanel from "./ServicesPanel";
import { HomePageContainer } from "./styles";
function HomePage() {
  return <HomePageContainer>
    <BackgroundPanel />
    <Header />
    <HomePanel />
    <ServicesPanel />
    <Footer/>
  </HomePageContainer>;
}
export default HomePage;