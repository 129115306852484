import React from "react";
import Button from "../../../common/Button";
import { HomePanelContainer,DescriptionPanel,Title,Description,ImagePanel,Image,BigButton } from "./styles";
import LandingImage from "./landingImage.png";
import { isMobile } from "../../../common/utils";
import Icon from "../../../common/Icon";
import colors from "../../../config/colors";
import { ButtonSpacer } from "../../../common/Button/styles";

function HomePanel() {
  return <HomePanelContainer id="home">
    <DescriptionPanel>
      <Title>Helping Organizations Build Stronger Pipeline Programs</Title>
      <Description>Let our software take care of your mentorship needs. We make it easy to build, manage, and track the success of your mentorship programs.</Description>
      <BigButton onClick={()=>{
        window.location.href="/app";
      }}>
        Create an account
        <ButtonSpacer/><ButtonSpacer/><ButtonSpacer/>
        <Icon type="next" color={colors.background} size={32}/>
      </BigButton>
    </DescriptionPanel>
   {!isMobile() &&  <ImagePanel>
      <Image src={LandingImage}/>
    </ImagePanel>}
  </HomePanelContainer>;
}
export default HomePanel;