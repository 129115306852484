import React from "react";
import { HeaderContainer, AppLogo, HeaderOptions, HeaderOption } from "./styles";
import LogoImage from './logo_transparent.png';
import Button from "../../../common/Button";
import { ButtonSpacer } from "../../../common/Button/styles";
import { isMobile } from "../../../common/utils";
function Header() {
  const [isOpen,setIsOpen]=React.useState(isMobile() ? false : true);
  return <HeaderContainer>
    {isMobile() && <Button icon="back" onClick={()=>{
      setIsOpen(true)
    }}/>}
    <AppLogo src={LogoImage} onClick={() => {
        document.getElementById("home").scrollIntoView({
          behavior: 'smooth'
        })
      }}/>
    <HeaderOptions isOpen={isOpen}>

    {isMobile() && <HeaderOption>
        <Button icon="cross" text="Close" onClick={() => {
          setIsOpen(false)
        }} />
      </HeaderOption>}
      <HeaderOption onClick={()=>{
          document.getElementById("services").scrollIntoView({
            behavior: 'smooth'
          });
          setIsOpen(false)
      }}>
        Services
      </HeaderOption>
      <HeaderOption href="https://forms.gle/1GMziDdvqRp5mHvM9">
        Book a Demo
      </HeaderOption>
      <HeaderOption href="app/terms-and-services">
        Terms And Services
      </HeaderOption>
      <HeaderOption>
        <Button text="Sign In" variant="primary" onClick={() => {
          window.location.href = "/app";
        }} />
      </HeaderOption>
    </HeaderOptions>
  </HeaderContainer>;
}
export default Header;