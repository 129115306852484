import styled from "styled-components";
import colors from "../../../config/colors";

export const BackgroundPanelContainer = styled.img`
    position:fixed;
    top:0px;
    left:0px;
    height:100vh;
    width:100vw;
    z-index:-5;
    // filter:blur(20px) brightness(1.5);
`;
export const Background=styled(BackgroundPanelContainer)`
    z-index:0;
    background-color:${colors.primary}33;
`;