import React from "react";
import Footer from "../../pages/HomePage/Footer";
import Header from "../../pages/HomePage/Header";
import TermsAndConditionsContent from "./TermsConditionsContent";
import { TermsAndConditionOuterContainer } from "./styles";

function TermsAndConditions() {
  return (
    <>
      <TermsAndConditionOuterContainer>
        <Header />
        <TermsAndConditionsContent />
        <Footer />
      </TermsAndConditionOuterContainer>
    </>
  );
}
export default TermsAndConditions;
