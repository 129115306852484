import React from "react";
import Button from "../Button";
import { ButtonSpacer } from "../Button/styles";
import { ModalContainer, ModalBackground, ModalContent,ModalTitle,ModalActions  } from "./styles";

function Modal({ children, title, actions, onClose, fixedWidth, width, minWidth, isWidth ,isHeight, boardView}) {
  return <ModalContainer isWidth={isWidth}>
    <ModalBackground onClick={()=>{onClose()}}>
      <ModalContent onClick={(e)=>{e.stopPropagation()}} fixedWidth={fixedWidth} width={width} minWidth={minWidth} isHeight={isHeight} boardView>
        <ModalTitle>{title}</ModalTitle>
        {children}
        {actions && <ModalActions>
          {actions.map(action => {
            return <><ButtonSpacer/><Button {...action} /></>
          })}
        </ModalActions>}
      </ModalContent>
    </ModalBackground>
  </ModalContainer>
}
export default Modal