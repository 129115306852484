import styled from "styled-components";
import { isMobile } from "../../../common/utils";
import colors from "../../../config/colors";
import {borderRadius} from "../../../common/styles";
export const HeaderContainer = styled.div`
    height: 120px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:0px 50px;
    box-sizing: border-box;
    top:0px;
    position:sticky;
    z-index: 10;
    backdrop-filter:blur(10px);
    background-color:${colors.background}55;
    ${isMobile() && `
        padding:0px;
        justify-content: center;
        height:80px;
    `}
`;
export const AppLogo = styled.img`
    height:50px;
`;
export const HeaderOptions = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
    z-index:5;
    left: ${props=>props.isOpen ? 0 : -100}vw;
    ${isMobile() && `
        position:fixed;
        flex-direction: column;
        height:100vh;
        width:calc(100vw - ${borderRadius}px);
        box-sizing: border-box;
        top:0px;
        background-color:${colors.background};
    `}
`;
export const HeaderOption = styled.a`
    margin:20px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    transition:all 0.2s;
    text-decoration:none;
    color:${colors.text};
    :hover{
        color:${colors.primary};
    }
`;